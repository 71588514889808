// @ts-strict-ignore
import type { PlanDrawerViewType } from "@clinic/shared/reactiveVar/usePlanWriterPlanDrawerVar/usePlanWriterPlanDrawerVar";
import type { MetricsType } from "@clinic/shared/scenes/AnalyticsDashboardPage/utils/AnalyticsDashboardTypes";
import type { ProductSwapPlanType } from "@clinic/shared/types/PlanType";
import type { ProductSwapAvailabilityStatus } from "@shared/types/availabilityStatus";

const clinicRoutes = {
  careCenter: {
    root: "/o/care-center",
    products: "/o/care-center/products",
    productDetail: "/o/care-center/products/:productId",
    getProductsPath: (planId?: string, pracId?: string, drawerViewType?: PlanDrawerViewType) => {
      const route = "/o/care-center/products";
      const params = [];

      if (!planId && !pracId) {
        return route;
      }

      if (planId) {
        params.push(`treatmentPlanId=${planId}`);
      }

      if (pracId) {
        params.push(`practitioner_id=${pracId}`);
      }

      if (drawerViewType) {
        params.push(`drawerOpen=${drawerViewType}`);
      }

      return `${route}?${params.join("&")}`;
    },
    getPathWithSearchParams: (
      redirectUrl: string,
      planId?: string,
      pracId?: string,
      labComparisons?: string
    ) => {
      const params = [];

      if (!planId && !pracId && !labComparisons) {
        return redirectUrl;
      }

      if (planId) {
        params.push(`treatmentPlanId=${planId}`);
      }

      if (pracId) {
        params.push(`practitioner_id=${pracId}`);
      }

      if (labComparisons) {
        params.push(`comparisons=${labComparisons}`);
      }

      return `${redirectUrl}?${params.join("&")}`;
    },
    getProductDetailPath: (id: string) => `/o/care-center/products/${id}`,
    productDetailVariant: "/o/care-center/products/:productId/:variantId",
    getProductDetailVariantPath: (productId: string, variantId: string) =>
      `/o/care-center/products/${productId}${variantId ? `/${variantId}` : ""}`,
    productDetailsOptimizedProducts:
      "/o/care-center/products/:productId/:variantId/optimized-products",
    getProductDetailsOptimizedProducsPath: (productId: string, variantId: string) =>
      `/o/care-center/products/${productId}${variantId ? `/${variantId}` : ""}/optimized-products`,
    compareProducts: "/o/care-center/products/:productId/:variantId/compare",
    getCompareProductsPath: (productId: string, variantId: string) =>
      `/o/care-center/products/${productId}${variantId ? `/${variantId}` : ""}/compare`,
    smartPlanBuilder: "/o/care-center/smart-plan-builder/:patientId",
    getSmartPlanBuilder: (patientId?: string) => `/o/care-center/smart-plan-builder/${patientId}`,
    resources: "/o/care-center/resources",
    labs: "/o/care-center/labs",
    getLabsPath: (planId?: string, practitionerId?: string) => {
      const params = [];

      if (planId) {
        params.push(`treatmentPlanId=${planId}`);
      }

      if (practitionerId) {
        params.push(`practitioner_id=${practitionerId}`);
      }

      return `/o/care-center/labs${params.length > 0 ? `?${params.join("&")}` : ""}`;
    },
    getLabsTemplatePath: (planId: string) =>
      `/o/care-center/labs?treatmentPlanId=${planId}&drawerOpen=templateSelectionView`,
    labsCompare: "/o/care-center/labs/compare",
    journeys: "/o/care-center/labs/journeys",
    journeysCareToolkit: "https://fullscript.com/lp/journeys-care-toolkit",
    labsCustomPanel: {
      view: (id: string) => `/o/care-center/labs/panel/${id}`,
      edit: (id: string) => `/o/care-center/labs/panel/${id}/edit`,
      editViewTest: (id: string, testId: string) =>
        `/o/care-center/labs/panel/${id}/edit/tests/${testId}`,
    },
    labsTemplatesAndPanels: "/o/care-center/labs/templates-and-panels",
    labsToolkit: "https://fullscript.com/lp/labs-toolkit",
    getTestDetailPath: (id: string, parentTestId?: string) =>
      `/o/care-center/labs/${id}${parentTestId ? `?parentTestId=${parentTestId}` : ""}`,
    getTestDetailAddOnPath: (id: string) => `/o/care-center/labs/${id}?isAddOnExpanded=true`,
    resourcesMyUploadDetails: "/o/care-center/resources/details/my-upload/:id",
    getResourcesMyUploadDetails: (id: string) => `/o/care-center/resources/details/my-upload/${id}`,
    resourcesFSDetails: "/o/care-center/resources/details/fs/:id",
    getResourcesFSDetails: (id: string) => `/o/care-center/resources/details/fs/${id}`,
    resourcesUpload: "/o/care-center/resources/upload",
    dailyPacks: "/o/care-center/daily-packs",
    dailyPacksBuilder: "/o/care-center/daily-packs/build",
    dailyPackPDP: "/o/care-center/daily-packs/pack/:variantId",
    getDailyPackPDP: (variantId: string) => `/o/care-center/daily-packs/pack/${variantId}`,
    dailyPackPill: "/o/care-center/daily-packs/pill/:pillId",
    getDailyPackPillPDP: (pillId: string) => `/o/care-center/daily-packs/pill/${pillId}`,
    getDailyPackPath: (redirectUrl: string, dailyPackId: string) => {
      const params = [];
      params.push(`dailyPack_id=${dailyPackId}`);

      return `${redirectUrl}?${params.join("&")}`;
    },
    templates: {
      base: "/o/care-center/templates",
      view: (id: string) => `/o/care-center/templates/${id}`,
      getTemplatesPath: (planId: string) => `/o/care-center/templates?treatmentPlanId=${planId}`,
    },
    planWriting: "/o/care-center/plan-writing",
    getPlanWritingPath: (planId: string, pracId?: string) => {
      let pracIdQueryParam = "";
      if (pracId) {
        pracIdQueryParam = `&practitioner_id=${pracId}`;
      }

      return `/o/care-center/plan-writing?treatmentPlanId=${planId}${pracIdQueryParam}`;
    },
    favorites: `/o/care-center/favorites`,
  },
  productSwaps: {
    root: "/o/product-swaps",
    listPage: "/o/product-swaps/:planType",
    getListPage: (planType: ProductSwapPlanType) => `/o/product-swaps/${planType}`,
    detailPage: "/o/product-swaps/:planType/:productId/:variantId",
    getDetailPage: (planType: ProductSwapPlanType, productId: string, variantId: string) =>
      `/o/product-swaps/${planType}/${productId}/${variantId}`,
  },
  productSwapsV2: {
    root: "/o/product-swaps",
    listPage: "/o/product-swaps/:status",
    getListPage: (
      status: ProductSwapAvailabilityStatus,
      params?: { practitionerId?: string; sort?: string }
    ) => {
      const baseUrl = `/o/product-swaps/${status}`;
      if (!params) return baseUrl;

      const queryParams = [];
      if (params.practitionerId) queryParams.push(`practitionerId=${params.practitionerId}`);
      if (params.sort) queryParams.push(`sort=${params.sort}`);

      return queryParams.length ? `${baseUrl}?${queryParams.join("&")}` : baseUrl;
    },
    detailPage: "/o/product-swaps/:status/:productId/:variantId",
    getDetailPage: (
      status: ProductSwapAvailabilityStatus,
      productId: string,
      variantId: string,
      practitionerId?: string
    ) =>
      `/o/product-swaps/${status}/${productId}/${variantId}${practitionerId ? `?practitionerId=${practitionerId}` : ""}`,
    howItWorksVideoLink: "https://www.youtube.com/embed/4XWqc0-meCo",
  },
  wholesale: {
    root: "/o/wholesale",
    shop: "shop",
    shopFullPath: () => `${clinicRoutes.wholesale.root}/${clinicRoutes.wholesale.shop}`,
    orders: "orders",
    ordersFullPath: () => `${clinicRoutes.wholesale.root}/${clinicRoutes.wholesale.orders}`,
    favorites: "favorites",
    favoritesFullPath: () => `${clinicRoutes.wholesale.root}/${clinicRoutes.wholesale.favorites}`,
    products: "products",
    productsFullPath: (productId?: string) => {
      const basePath = `${clinicRoutes.wholesale.root}/${clinicRoutes.wholesale.products}`;
      if (productId) {
        return `${basePath}/${productId}`;
      }

      return basePath;
    },
    labKitDetails: (labKitId: string) =>
      `${clinicRoutes.wholesale.root}/${clinicRoutes.wholesale.labKits}/${labKitId}`,
    labKits: "lab-kits",
    labKitsFullPath: (labKitId?: string, practitionerId?: string) => {
      const basePath = `${clinicRoutes.wholesale.root}/${clinicRoutes.wholesale.labKits}`;
      if (labKitId) {
        return `${basePath}/${labKitId}${practitionerId ? `?practitioner_id=${practitionerId}` : ""}`;
      }

      return basePath;
    },
    policies: () => `${clinicRoutes.wholesale.root}/policies`,
    swapVariant: variantId => `${clinicRoutes.wholesale.shopFullPath()}?swap="${variantId}"`,
  },
  manage: {
    favorites: "/o/manage/favorites",
  },
  home: "/o/home",
  onboarding: "/o/onboarding",
  activateLabKit: (patientId?: string) => {
    if (patientId) {
      return `/o/activate-kit?patientId=${patientId}`;
    }

    return "/o/activate-kit";
  },
  catalog: "/o/catalog",
  catalogProduct: "/o/catalog/products",
  promotions: "/o/catalog/promotions",
  checkout: "/o/checkout",
  dailyPacksInfo: "https://fullscript.com/lp/fullscript-daily-packs",
  wholesalePolicies: "/o/wholesale_policies",
  wholesale_orders: "/o/wholesale_orders",
  naturalPartnersOrders: "/o/wholesale_orders/np",
  treatment_plans: "/o/treatment_plans",
  treatment_plans_new: "/o/treatment_plans/new",
  registerLabPartner: "/o/register_lab_partner",
  supplement_plans: "o/supplement_plans",
  patients: "/o/patients",
  patients_import: "/o/patients/import",
  patients_new: "/o/patients/new",
  patients_orders: "/o/patients/orders",
  patients_lab_orders: "/o/patients/lab-orders",
  product_swapping: "/o/product_swapping", // NOTE: Old product swap route
  swapPages: {
    // NOTE: Old product swap routes
    current: "/o/product_swapping/current",
    success: "/o/product_swapping/success",
    past: "/o/product_swapping/past",
  },
  protocols: "/o/protocols",
  protocols_new: "/o/protocols/new",
  protocols_fullscript: "/o/protocols/fullscript",
  choose_practitioner: "/o/choose_practitioner",
  choosePractitionerWithRedirectUrl: (redirectUrl: string, parameters: URLSearchParams) =>
    `/o/choose_practitioner?redirect_url=${redirectUrl}&redirect_params=${parameters.toString()}`,
  reports: "/o/reports",
  payouts_report: "/o/reports/payouts_report",
  earnings_report: "/o/reports/earnings_report",
  choose_earnings_report: "/o/reports/earnings_report/choose_earnings_report",
  choose_payout_dates: "/o/reports/earnings_report/choose_payout_dates",
  review_earnings_report: "/o/reports/earnings_report/review_earnings_report",
  downloadEarningReport: (reportType: string, storeLegacyId: number) =>
    `/o/payable/spree-store!${storeLegacyId}/${reportType}.xlsx`,
  staffs: "/c/staffs", // even though this is /c/ its accessible to pracs as well
  logout: "/logout",
  lab_recommendations: "/o/lab_recommendations",
  support: "https://support.fullscript.com",
  fsTOS: "https://fullscript.com/terms-of-service",
  scheduledAdherenceSurveys: "https://support.fullscript.com/articles/scheduled-adherence-surveys/",
  globalPatientAccessSettings:
    "https://support.fullscript.com/articles/global-patient-access-settings/",
  productCatalogPermissions:
    "https://support.fullscript.com/articles/catalog-permissions-and-access-settings/",
  onSiteProducts: "https://support.fullscript.com/articles/on-site-products/",
  wellevateMigrationSupport:
    "https://support.fullscript.com/hc/en-us/sections/13558530393115-Wellevate-migration",
  wellevateMigrationToolkit: "https://fullscript.com/lp/migration-toolkit-int",
  wellevatePatientSupportVideoLink: "https://www.youtube.com/embed/ZIuUFx1g6wY",
  wellevateLoyalistSupportVideoLink: "https://www.youtube.com/embed/9WgeUi92ECw",
  labsAuthNetworkLink: "https://support.fullscript.com/articles/authorization-network/",
  favorites: "/o/favorites",
  favoriteCategory: favoriteCategoryId => `/o/favorites/${favoriteCategoryId}`,
  welcome: "/welcome",
  whats_new: "https://fullscript.com/whats-new",
  integrations: "/o/integrations",
  libraries: "/o/libraries",
  storeSettings: {
    base: "/o/store_settings",
    dispensary: "/o/store_settings/dispensary",
    integrations: "/o/store_settings/integrations",
    account: "/o/store_settings/account_settings",
    staff: "/o/store_settings/staff_settings",
    features: "/o/store_settings/features",
    landingPage: "/o/store_settings/landing_page",
    payments: "/o/store_settings/payments",
    paymentsEdit: "/o/store_settings/payments/edit",
    paymentsPayouts: "/o/store_settings/payments/payouts",
    permissions: "/o/store_settings/permissions",
    permissionsPatient: "/o/store_settings/permissions/patient",
    promotions: "/o/store_settings/promotions",
    storeInfo: "/o/store_settings/dispensary_info",
    websiteButtons: "/o/store_settings/website_buttons",
    labs: "/o/store_settings/labs",
  },
  analyticsDashboard: {
    root: "/o/analytics-dashboard",
    detailPage: "/o/analytics-dashboard/:metrics",
    getDetailPage: (metrics: MetricsType) => `/o/analytics-dashboard/${metrics}`,
    ordersPlacedHandoutLink:
      "https://fs-marketing-files.s3.amazonaws.com/handouts/fullscript-mobile-app-2025.pdf",
    plansSectionHandoutLink: "https://fullscript.com/blog/writing-more-engaging-treatment-plans",
    individualPlanConversionRateHandoutLink:
      "https://support.fullscript.com/articles/similar-products-for-practitioners/",
    educationalVideoLink: "https://www.youtube.com/embed/r7xyecWMFcY",
    firstTimeAutoRefillsHandoutLink:
      "https://support.fullscript.com/articles/autorefills-save-discount/",
    autoRefillOrdersHandoutLink:
      "https://fs-marketing-files.s3.amazonaws.com/handouts/auto-refill-education.pdf",
    profitMarginLearnMoreLink: "https://support.fullscript.com/articles/profit-dispensaries/",
    revenueCalculatorCALink: "https://fullscript1.outgrow.us/ca-fullscript-revenue-calculator-2025",
    revenueCalculatorUSLink: "https://fullscript1.outgrow.us/fullscript-revenue-calculator-2025",
  },
  staff_settings: "/o/staff_settings",
  accountSettings: "/o/account_settings",
  accountSettingsBasicInfo: "/o/account_settings/basic_info",
  accountSettingsSecurity: "/o/account_settings/security",
  accountSettingsPreferences: "/o/account_settings/preferences",
  accountSettingsPaymentInfo: "/o/account_settings/payment_info",
  accountSettingsConnectedAccounts: "/o/account_settings/connected_accounts",
  accounts: "/accounts",
  stopImpersonating: "/a/sessions/stop_impersonating",
  genAIResearchAssistant: "/o/research_assistant",
  integrationDetails: integrationId => `/o/integrations/${integrationId}`,
  newTreatmentPlanForPatient: patientId => `/o/treatment_plans/new?patient_id=${patientId}`,
  choosePractitionerBase: "/o/choose_practitioner",
  choosePractitioner: patientId => `/o/choose_practitioner?patient_id=${patientId}`,
  product: productId => `/o/catalog/products/${productId}`,
  productWithVariant: (productId, variantId) => `/o/catalog/products/${productId}/${variantId}`,
  swapVariant: masterVariantId => `/o/catalog?swap="${masterVariantId}"`,
  officeTreatmentPlansEdit: treatmentPlanId => `/o/treatment_plans/${treatmentPlanId}/edit`,
  patient: id => `/o/patients/${id}`,
  patientTreatmentPlans: id => `/o/patients/${id}/treatment_plans`,
  patientSupplementPlan: id => `/o/patients/${id}/current_supplements`,
  printSupplementPlan: id => `/o/supplement_plans/${id}?print=true`,
  patientLabs: id => `/o/patients/${id}/labs`,
  patientLabRecommendationDetails: (patientId, labRecommendationId) =>
    `/o/patients/${patientId}/labs/${labRecommendationId}`,
  patientLabRecommendationResultDetails: (patientId, labRecommendationId) =>
    `/o/patients/${patientId}/labs/${labRecommendationId}/results`,
  patientLabRecommendation: (labRecommendationId: string) =>
    `/u/labs/recommendation/${labRecommendationId}`,
  patientOrders: id => `/o/patients/${id}/orders`,
  patientStart: id => `/o/patients/${id}/start`,
  patientsEdit: id => `/o/patients/${id}/edit`,
  patientsCheckout: id => `/o/patients/${id}/checkout`,
  protocolsTab: tabId => `/o/protocols/${tabId}`,
  startTreatmentPlanCheckout: (legacyPatientId, legacyTreatmentPlanId) =>
    `/o/patients/${legacyPatientId}/checkout/populate_with_treatment_plan.html?reset_order=true&treatment_plan_id=${legacyTreatmentPlanId}`,
  patientsTreatmentPlanInfo: (patientId, treatmentPlanId) =>
    `/o/patients/${patientId}/treatment_plans/${treatmentPlanId}`,
  patientPageWithTreatmentPlan: (patientId, treatmentPlanId) =>
    `/o/patients/${patientId}?treatment_plan=${treatmentPlanId}`,
  patientsOrderInfo: (patientId, orderId) => `/o/patients/${patientId}/orders/${orderId}`,
  peerReferrals: "/o/peer_referrals",
  orderPdfLink: orderNumber => `/o/orders/${orderNumber}.pdf`,
  orderPrintLink: orderNumber => `/o/orders/${orderNumber}.html?print=true`,
  orderLabsPdfLink: orderNumber => `/o/orders/${orderNumber}.pdf?labs=true`,
  orderLabsPrintLink: orderNumber => `/o/orders/${orderNumber}.html?print=true&labs=true`,
  staffEdit: id => `/c/staffs/${id}/edit`,
  wellevateMigration: "/o/wellevate_migration",
  buildPlan: "/o/build_plan",
  buildPlanMyTemplates: "/o/build_plan/my_templates",
  buildPlanFullscriptCreated: "/o/build_plan/fullscript_created_templates",
  plansPageWithTab: "/o/plans/:tabId",
  plansPageIndividual: `/o/plans/individual_plans`,
  plansPageMultiPatient: `/o/plans/multi_patient`,
  plansPageTemplate: `/o/plans/templates`,
  plansPage: "/o/plans",
  plansNew: "/o/plans/new",
  plansNewForPatient: patientId => `/o/plans/new?patient_id=${patientId}`,
  plansNewIndividual: "/o/plans/new?plan_type=individual",
  plansNewMultipatient: "/o/plans/new?plan_type=multi_patient",
  plansNewTemplate: "/o/plans/new?plan_type=template",
  officePlansEdit: (treatmentPlanId: string, pracId?: string) => {
    let pracIdQueryParam = "";
    if (pracId) {
      pracIdQueryParam = `?practitioner_id=${pracId}`;
    }

    return `/o/plans/${treatmentPlanId}/edit${pracIdQueryParam}`;
  },
  newInOfficeCart: id => `/b/patients/${id}/checkout/cart`,
  checkoutAddress: patientId => `/b/patients/${patientId}/checkout/address`,
  recordAudio: patientId => `/o/patients/${patientId}/audio_recordings`,
  audioSession: (patientId, audioSessionId) =>
    `/o/patients/${patientId}/audio_sessions/${audioSessionId}`,
  transcriptSummary: (patientId, audioSessionId) =>
    `/o/patients/${patientId}/audio_sessions/${audioSessionId}/summary`,
  assist: patientId => `/o/patients/${patientId}/assist`,
  assistFromId: (patientId, copilotSessionId) =>
    `/o/patients/${patientId}/assist/${copilotSessionId}`,
  newFullscriptExperience: "https://fullscript.com/lp/new-fullscript-us",
  qualityProgram: {
    tested: "https://fullscript.com/lp/tested-badge",
    preferred: "https://fullscript.com/lp/quality-badge#top-pick-badge",
  },
  emersonSwitch: "/user/platform-redirect?target_platform_name=emerson",
  rupaMigration: "/o/migrate",
} as const;

export { clinicRoutes };
